<template>
  <div>
    <q-form ref="editForm">
      <c-card title="LBLBASEINFO" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn
              v-show="editable && isOld && !disabled"
              :url="completeUrl"
              :isSubmit="isComplete"
              :param="traningEdu"
              mappingType="PUT"
              label="계획수립완료"
              icon="check"
              @beforeAction="completeInfo"
              @btnCallback="completeCallback" />
            <c-btn
              v-show="editable && !disabled"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="traningEdu"
              :mappingType="mappingType"
              label="LBLSAVE"
              icon="save"
              @beforeAction="saveInfo"
              @btnCallback="saveCallback" />
            <c-btn
              v-show="editable && isOld && !disabled"
              label="삭제"
              icon="delete_forever"
              @btnClicked="deleteInfo" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <c-text
              required
              :editable="editable"
              :disabled="disabled"
              name="traninigEduName"
              label="훈련·교육 계획명"
              v-model="traningEdu.traninigEduName" />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-datepicker
              required
              :editable="editable"
              :disabled="disabled"
              default="today"
              type="datetime"
              name="traninigEduDt"
              label="훈련·교육 일시"
              v-model="traningEdu.traninigEduDt" />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-text
              required
              :editable="editable"
              :disabled="disabled"
              name="locationName"
              label="장소"
              v-model="traningEdu.locationName" />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-dept
              required
              :editable="editable"
              :disabled="disabled"
              type="edit"
              name="deptCd"
              v-model="traningEdu.deptCd" />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-plant
              required
              :editable="editable"
              :disabled="disabled"
              type="edit"
              name="plantCd"
              v-model="traningEdu.plantCd" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <c-checkbox
              :editable="editable"
              :disabled="disabled"
              :isArray="false"
              codeGroupCd="SOP_FIRE_FIGHTING_TRANING_EDU_TARGET_CD"
              label="대상"
              name="sopFireFightingTraningEduTargetCd"
              v-model="traningEdu.sopFireFightingTraningEduTargetCd"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <c-checkbox
              :editable="editable"
              :disabled="disabled"
              :isArray="false"
              codeGroupCd="SOP_FIRE_FIGHTING_TRANING_EDU_PRACTICE_CD"
              label="실습 종류"
              name="sopFireFightingTraningEduPracticeCd"
              v-model="traningEdu.sopFireFightingTraningEduPracticeCd"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <c-checkbox
              :editable="editable"
              :disabled="disabled"
              :isArray="false"
              codeGroupCd="SOP_FIRE_FIGHTING_TRANING_EDU_THEORY_CD"
              label="이론 종류"
              name="sopFireFightingTraningEduTheoryCd"
              v-model="traningEdu.sopFireFightingTraningEduTheoryCd"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <c-checkbox
              :editable="editable"
              :disabled="disabled"
              :isArray="false"
              codeGroupCd="SOP_FIRE_FIGHTING_TRANING_EDU_FORM_CD"
              label="훈련 형태"
              name="sopFireFightingTraningEduFormCd"
              v-model="traningEdu.sopFireFightingTraningEduFormCd"
              @datachange="datachange"
            />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-text
              :editable="editable&&eduFormEditable"
              :disabled="disabled"
              name="participatingInstitutions"
              label="참여기관"
              v-model="traningEdu.participatingInstitutions" />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-field 
              required
              :disabled="true"
              :editable="editable"
              :data="traningEdu"
              deptValue="fireSafetyManagerDeptCd"
              type="dept_user" 
              label="소방안전관리자" 
              name="fireSafetyManagerId" 
              v-model="traningEdu.fireSafetyManagerId" />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-1 col-lg-1">
            <c-select 
              :disabled="true"
              :editable="editable"
              codeGroupCd="FIRE_SAFETY_MANAGER_QUALIT_CLASS_CD"
              itemText="codeName"
              itemValue="code"
              type="edit" 
              label="소방안전관리자 구분" 
              name="fireSafetyManagerQualitClassCd" 
              v-model="traningEdu.fireSafetyManagerQualitClassCd" />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
            <c-fire-fighting-training-scenario
              :editable="editable"
              :disabled="disabled"
              name="sopFireFightingTraningScenarioId"
              label="훈련 시나리오"
              v-model="traningEdu.sopFireFightingTraningScenarioId"
              @dataChange="scenarioChange" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              name="traningPlan"
              label="훈련계획"
              v-model="traningEdu.traningPlan" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              name="eduPlan"
              label="교육계획"
              v-model="traningEdu.eduPlan" />
          </div>
        </template>
      </c-card>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-upload
            :attachInfo="attachTeachingMaterialsInfo"
            :editable="editable&&!disabled"
            label="교보재" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" style="margin-top: -17px !important;">
          <c-card title="평가계획 정보" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-datepicker
                  required
                  :editable="editable"
                  :disabled="disabled"
                  default="today"
                  type="datetime"
                  name="evaluationPlanDt"
                  label="평가 일시"
                  v-model="traningEdu.evaluationPlanDt" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-field
                  :disabled="disabled"
                  :editable="editable"
                  :data="traningEdu"
                  :plantCd="traningEdu.plantCd"
                  deptValue="evaluationDeptCd"
                  type="dept_user"
                  label="평가자"
                  name="evaluationUserId"
                  v-model="traningEdu.evaluationUserId" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  name="evaluationMethod"
                  label="평가방법"
                  v-model="traningEdu.evaluationMethod" />
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
  </div>
</template>
<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'fireFightingTraningEduPlanInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingTraningEduId: '',
      }),
    },
    traningEdu: {
      type: Object,
      default: () => ({
        sopFireFightingTraningEduId: '',  // 소방 훈련·교육 계획/결과 일련번호
        plantCd: '',  // 사업장코드
        traninigEduDt: '',  // 훈련·교육 일시
        sopFireFightingTraningEduStepCd: '',  // 소방 훈련·교육 계획/결과 진행상태
        traninigEduName: '',  // 소방 훈련·교육 계획/결과명
        locationName: '',  // 장소
        sopFireFightingTraningEduTargetCd: '',  // 소방 훈련·교육 대상
        sopFireFightingTraningEduPracticeCd: '',  // 소방 훈련·교육 실습
        sopFireFightingTraningEduTheoryCd: '',  // 소방 훈련·교육 이론
        deptCd: '',  // 주관부서
        fireSafetyManagerId: '',  // 소방안전관리자
        fireSafetyManagerQualitClassCd: null,  // 소방안전관리자 자격구분
        fireSafetyManagerNote: '',  // 소방안전관리자 비고
        sopFireFightingTraningEduFormCd: '',  // 소방 훈련 형태
        participatingInstitutions: '',  // 참여기관
        sopFireFightingTraningScenarioId: '',  // 소방 훈련 시나리오 일련번호
        traningPlan: '',  // 훈련계획
        eduPlan: '',  // 교육계획
        evaluationPlanDt: '',  // 평가계획일시
        evaluationUserId: '',  // 평가자
        evaluationMethod: '',  // 평가방법
        evaluationDt: '',  // 평가일시
        educationInstructorName: '',  // 교육강사
        totalNumber: '',  // 총원
        attendNumber: '',  // 참여인원
        nonAttendNumber: '',  // 미참석
        selfTotalNumber: '',  // 자위소방대 총원
        selfLeaderId: '',  // 자위소방대 대장명
        selfLeaderPhoneNumber: '',  // 자위소방대 대장 연락처
        selfUnitLeaderNumber: '',  // 자위소방대 부대장 인원
        selfNotiContactNumber: '',  // 자위소방대 통보연락 인원
        selfInitFireExtingNumber: '',  // 자위소방대 초기소화 인원
        selfEvacuGuidNumber: '',  // 자위소방대 피난유도 인원
        selfEmergencyContactNumber: '',  // 자위소방대 비상연락 인원
        mainContent: '',  // 주요내용
        mainAchievements: '',  // 주요성과
        problem: '',  // 문제점
        supplementaryMatters: '',  // 보완사항
        actionTaken: '',  // 조치사항
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        users: [],
        scenarios: [],
      }),
    },
    attachTeachingMaterialsInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'FIRE_FIGHTING_EDU_TEACHING_MATERIALS',
        taskKey: '',
      }),
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: true,
      isSave: false,
      isComplete: false,
      detailScenarioUrl: '',
      saveUrl: '',
      completeUrl: '',
      deleteUrl: '',
      mappingType: 'PUT',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
  },
  computed: {
    eduFormEditable() {
      return Boolean(this.traningEdu.sopFireFightingTraningEduFormCd)
        && this.traningEdu.sopFireFightingTraningEduFormCd.indexOf('FFTEF00005') > -1
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailScenarioUrl = selectConfig.fft.fireFightingTrainingScenario.get.url;
      this.saveUrl = transactionConfig.fft.fireFightingTraningEdu.update.url
      this.deleteUrl = transactionConfig.fft.fireFightingTraningEdu.delete.url
      this.completeUrl = transactionConfig.fft.fireFightingTraningEdu.plancomplete.url
      // code setting
      // list setting
    },
    datachange() {
      if (!this.eduFormEditable) {
        this.traningEdu.participatingInstitutions = ''
      }
    },
    scenarioChange(newVal, oldVal) {
      if (newVal && oldVal) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: '시나리오에 따른 상황정보가 변경됩니다.\n진행하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.setScenario()
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else if (!newVal) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: '시나리오에 따른 상황정보가 초기화됩니다.\n진행하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$set(this.traningEdu, 'scenarios', [])
            this.$set(this.traningEdu, 'scenarioFlag', true)
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        this.setScenario()
      }
    },
    setScenario() {
      if (!this.traningEdu.sopFireFightingTraningScenarioId) return;
      this.$http.url = this.$format(this.detailScenarioUrl, this.traningEdu.sopFireFightingTraningScenarioId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$set(this.traningEdu, 'scenarios', this.$_.map(_result.data.situations, situation => {
          return this.$_.extend(situation, {
            sopFireFightingTraningScenarioResultId: uid(),  // 소방 훈련 시나리오 결과 일련번호
            sopFireFightingTraningEduId: this.popupParam.sopFireFightingTraningEduId,  // 소방 훈련·교육 계획/결과 일련번호
            regUserId: this.$store.getters.user.userId,  // 등록자 ID
            editFlag: 'C'
          })
        }))
        this.$set(this.traningEdu, 'scenarioFlag', true)
      },);
    },
    saveInfo() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.fft.fireFightingTraningEdu.update.url
        this.mappingType = 'PUT'
      } else {
        this.saveUrl = transactionConfig.fft.fireFightingTraningEdu.insert.url
        this.mappingType = 'POST'
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.traningEdu.regUserId = this.$store.getters.user.userId;
              this.traningEdu.chgUserId = this.$store.getters.user.userId;

              console.log(this.$_.cloneDeep(this.traningEdu))
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.popupParam.sopFireFightingTraningEduId) {
        this.$set(this.attachTeachingMaterialsInfo, 'taskKey', result.data)
        this.$set(this.attachTeachingMaterialsInfo, 'isSubmit', uid())
      }
      this.popupParam.sopFireFightingTraningEduId = result.data
      this.$set(this.traningEdu, 'scenarioFlag', false)
      this.$emit('getDetail');
    },
    completeInfo() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: '계획수립완료하시겠습니까?', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.traningEdu.chgUserId = this.$store.getters.user.userId;

              this.isComplete = !this.isComplete;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.traningEdu, 'scenarioFlag', false)
      this.$emit('getDetail');
    },
    deleteInfo() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.sopFireFightingTraningEduId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>